<template>
  <div class="col-gap-3 w-full">
    <label v-if="field.inputLabel" :for="field.inputName">{{ field.inputLabel }}</label>
    <input
      :id="field.inputName"
      v-model.trim="value"
      :placeholder="field.placeholder ?? 'Введите ...'"
      :class="`${field.className ?? ''} ${errorMessage ? 'error' : ''}`"
      :maxlength="maxlength"
      autocomplete="on"
      :type="field.inputType ?? 'text'"
      @input="$emit('input', ($event.target as any).value)"
    />
    <span class="text-red-400 -mt-2 block h-2">{{ errorMessage }}</span>
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { type FieldSettings } from '~/bin/publisher/payments/credentials/templates';
import { validateRequiredField } from '~/helpers/validators/field-validators';
/* eslint-disable no-undef */

interface Props {
  field: FieldSettings;
  initialValue?: string | undefined;
  required?: boolean | undefined;
  maxlength?: string | undefined;
}

const props = withDefaults(defineProps<Props>(), {
  initialValue: '',
  required: true,
  maxlength: undefined,
});
defineEmits(['input']);

const { value, errorMessage, setState } = useField<string>(
  props.field.inputName,
  props.field.validatingFunction ?? (props.required ? validateRequiredField : () => true),
  {
    initialValue: props.initialValue,
  },
);

defineExpose({
  valid: computed(
    () => typeof unref(errorMessage) !== 'string' && (!props.required || unref(value)),
  ),
  value,
  setState: (value: string) => setState({ value }),
});
</script>
